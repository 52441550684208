import gql from "graphql-tag";

export const epochsGql = gql`
  query Epochs($filters: EpochFiltersInput) {
    epochs(filters: $filters) {
      ... on EpochResults {
        results {
          id
          epochNo
          endTimestamp
          blocksCount
          transactionsCount
          transactionsAmount
          transactionsFee
          poolsCount
          poolsWithStakeCount
          poolsWithBlocksCount
          delegationAmount
          rewardsAmount
          poolsFee
          accountsCount
          delegatorsCount
          rewardsCount
        }
      }

      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
