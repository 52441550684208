
import { computed, defineComponent, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import BANCard from "@/shared/components/cards/BANCard.vue";
import GhostListHeader from "@/shared/components/layouts/GhostListHeader.vue";
import List from "@/shared/components/lists/List.vue";
import BlockCardEpoch from "@/shared/components/cards/BlockCardEpoch.vue";
import { useGetEpochList } from "@/shared/composables/api/useGetEpochList";
import { useQuery } from "@vue/apollo-composable";
import { epochStatisticsGql } from "@/api/epochs/epochStats";
import {
  CurrentEpoch,
  CurrentEpoch_currentEpoch_CurrentEpoch,
} from "@/api/epochs/__generated__/CurrentEpoch";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import routeNames from "@/web/router/routeNames";
import moment from "moment";
import GlobalSearchInput from "@/shared/components/forms/GlobalSearchInput.vue";
import PageHeaderTitle from "@/shared/components/typography/PageHeaderTitle.vue";
import lessVariable from "@/less/variables.less";
import AmountText from "@/shared/components/typography/AmountText.vue";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    const epochStatistics = useQuery<CurrentEpoch>(epochStatisticsGql, {});
    const {
      mappedDataForDisplay,
      showMoreIsLoading,
      showMore,
      isLoading: isListLoading,
    } = useGetEpochList();
    const parsedGqlEpochStatistics = computed(() =>
      parseGqlResponse<CurrentEpoch_currentEpoch_CurrentEpoch>(
        "CurrentEpoch",
        epochStatistics?.result?.value
      )
    );
    const counterSeconds = ref(0);

    const gotoEpochDetails = (hash) => ({
      name: routeNames.epochDetails,
      params: { hash },
    });

    const counterTimer = window.setInterval(() => counterSeconds.value++, 1000);

    const cardDetails = computed(() => {
      return [
        {
          cardTitle: t("current epoch"),
          cardAmount: parsedGqlEpochStatistics?.value?.data?.epochNo,
        },
        {
          cardTitle: t("blocks"),
          cardAmount: parsedGqlEpochStatistics?.value?.data?.blocksCount,
        },
        {
          cardTitle: t("slots"),
          cardAmount:
            moment(parsedGqlEpochStatistics?.value?.data?.endTimestamp)
              .utc()
              .unix() -
            moment(parsedGqlEpochStatistics?.value?.data?.startTimestamp)
              .utc()
              .unix() +
            counterSeconds.value,
          slotName: "slots",
        },
      ];
    });

    onUnmounted(() => {
      if (counterTimer) {
        window.clearInterval(counterTimer);
      }
    });

    return {
      epochStatistics,
      t,
      cardDetails,
      mappedDataForDisplay,
      showMore,
      showMoreIsLoading,
      gotoEpochDetails,
      isListLoading,
      lessVariable,
    };
  },
  components: {
    AmountText,
    BANCard,
    GhostListHeader,
    List,
    BlockCardEpoch,
    GlobalSearchInput,
    PageHeaderTitle,
  },
});
