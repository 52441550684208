// @see https://roadmap.cardano.org/en/shelley/
// Update as the phases progress
export const currentRoadmap = (epochNo: string | number) => {
  const epochNoParsed =
    typeof epochNo === "number" ? epochNo : parseFloat(epochNo);

  if (epochNoParsed < 208) {
    return "Byron";
  } else {
    return "Shelley";
  }
};
