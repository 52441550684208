
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import BlockCardLayout from "@/shared/components/cards/BlockCardLayout.vue";
import BlockCardLayoutBoxedItem from "@/shared/components/cards/BlockCardLayoutBoxedItem.vue";
import { Epochs_epochs_EpochResults_results } from "@/api/epochs/__generated__/Epochs";
import { formatDate, formatTime } from "@/shared/utils/date";
import { currentRoadmap } from "@/shared/utils/ada";
import AmountText from "@/shared/components/typography/AmountText.vue";
import Tooltip from "@/shared/components/cards/Tooltip.vue";

export default defineComponent({
  props: {
    isEven: {
      type: Boolean,
    },
    itemDetails: {
      type: Object as PropType<Epochs_epochs_EpochResults_results | null>,
    },
  },
  setup() {
    const t = useI18n().t;

    return {
      t,
    };
  },
  components: {
    BlockCardLayout,
    BlockCardLayoutBoxedItem,
    AmountText,
    Tooltip,
  },
  methods: {
    formatTime,
    formatDate,
    currentRoadmap,
  },
});
