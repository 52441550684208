import { computed, ref, toRaw, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { epochsGql } from "@/api/epochs/epochList";
import {
  Epochs,
  Epochs_epochs_EpochResults,
  Epochs_epochs_EpochResults_results,
  EpochsVariables,
} from "@/api/epochs/__generated__/Epochs";
import { convertAmountToAda } from "@/shared/utils/numbers";
import { useFetchMoreListMerge } from "@/shared/composables/useMergeList";

const epochListDisplayMapper = (
  itemList: (Epochs_epochs_EpochResults_results | null)[] | null
) => {
  return (itemList ?? []).map((item) => {
    return {
      id: item?.id,
      epochNo: item?.epochNo,
      endTimestamp: item?.endTimestamp,
      blocksCount: item?.blocksCount,
      transactionsCount: item?.transactionsCount,
      transactionsAmount: convertAmountToAda(item?.transactionsAmount),
      transactionsFee: convertAmountToAda(item?.transactionsFee),
      poolsCount: item?.poolsCount,
      poolsWithStakeCount: item?.poolsWithStakeCount,
      poolsWithBlocksCount: item?.poolsWithBlocksCount,
      delegationAmount: convertAmountToAda(item?.delegationAmount),
      rewardsAmount: convertAmountToAda(item?.rewardsAmount),
      poolsFee: convertAmountToAda(item?.poolsFee),
      accountsCount: item?.accountsCount,
      delegatorsCount: item?.delegatorsCount,
      rewardsCount: item?.rewardsCount,
    };
  });
};

// TODO: Add additional filters as needed if a screen has the need to support custom filters
export const useGetEpochList = () => {
  const mergedList = ref<ReturnType<typeof epochListDisplayMapper>>([]);
  const { fetchMoreListMerge } = useFetchMoreListMerge();
  const showMoreButtonTriggered = ref(false);
  const apiResponseList = useQuery<Epochs, EpochsVariables>(epochsGql, {
    filters: {
      pagination: {
        offset: 0,
      },
    },
  });

  const parsedGqlBlockList = computed(() =>
    parseGqlResponse<Epochs_epochs_EpochResults>(
      "EpochResults",
      apiResponseList?.result?.value
    )
  );

  watch(
    parsedGqlBlockList,
    (newParsedGqlBlockList) => {
      if (
        newParsedGqlBlockList.data?.results &&
        newParsedGqlBlockList.data?.results.length > 1
      ) {
        const mappedDisplay = epochListDisplayMapper(
          newParsedGqlBlockList.data?.results
        );
        const newMergedList = fetchMoreListMerge<
          ArrayElement<ReturnType<typeof epochListDisplayMapper>>
        >(
          toRaw(mergedList.value),
          mappedDisplay ?? [],
          "id",
          showMoreButtonTriggered.value
        );

        mergedList.value = newMergedList;
        showMoreButtonTriggered.value = false;
      }
    },
    {
      immediate: true,
    }
  );

  const showMore = () => {
    showMoreButtonTriggered.value = true;
    apiResponseList.refetch({
      filters: {
        pagination: {
          offset: mergedList.value.length,
        },
      },
    });
  };

  return {
    mappedDataForDisplay: mergedList,
    showMore,
    showMoreIsLoading: showMoreButtonTriggered,
    isLoading: apiResponseList?.loading,
  };
};
