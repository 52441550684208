import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67e424a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block-card-layout__boxed-item" }
const _hoisted_2 = { class: "block-card-layout__boxed-item-inline-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "label", {}, undefined, true),
        _renderSlot(_ctx.$slots, "prefix", {}, undefined, true),
        _renderSlot(_ctx.$slots, "content", {}, undefined, true),
        _renderSlot(_ctx.$slots, "suffix", {}, undefined, true)
      ]),
      _: 3
    }),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "inline-label", {}, undefined, true)
        ])
      ]),
      _: 3
    })
  ]))
}